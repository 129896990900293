import React from 'react'
import styled from 'styled-components';

export default function Thanks() {
    return (
        <Wrapper>
            <Misty src="/images/misty.png" alt="Misty" />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    background-color: rgb(208, 20, 34); 
    color: white;
    height: 100vh;
    width: 100%;
    img {
        bottom: -3px;
        text-align: center;
        position: fixed;
    }
`;

const Misty = styled.img`
    width: 80%;
    margin-left: 20px;
    @media screen and (min-width: 375px) {
        width: 90%;
    }
`;

import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { withRouter } from 'react-router-dom'

import { fadeInOut, starburst, oliviaHolding, oliviaThrowing, pokeBall, pokeBall411, pokeBall812 } from "../DefaultStyles";

function Countdown(props) {

  useEffect(() => {
    setTimeout(() => {
      props.history.push('/home');
      }, 11300);

}, [props.history])


    return (
        <Wrapper>
            <Three>
                <g transform="matrix(272.805,0,0,267.242,13.6406,193.21)">
                    <path d="M0.31,0.014C0.213,0.014 0.14,-0.007 0.091,-0.051C0.042,-0.094 0.017,-0.156 0.017,-0.238L0.209,-0.238C0.209,-0.204 0.217,-0.179 0.234,-0.163C0.251,-0.146 0.275,-0.138 0.307,-0.138C0.335,-0.138 0.358,-0.144 0.376,-0.157C0.394,-0.17 0.403,-0.19 0.403,-0.218C0.403,-0.244 0.394,-0.264 0.377,-0.277C0.361,-0.291 0.336,-0.298 0.304,-0.298L0.257,-0.298L0.257,-0.429L0.304,-0.429C0.331,-0.429 0.352,-0.436 0.367,-0.448C0.382,-0.461 0.39,-0.479 0.39,-0.501C0.39,-0.524 0.382,-0.541 0.366,-0.552C0.351,-0.562 0.333,-0.567 0.312,-0.567C0.288,-0.567 0.269,-0.561 0.254,-0.548C0.239,-0.535 0.232,-0.516 0.232,-0.491L0.042,-0.491C0.045,-0.52 0.052,-0.548 0.063,-0.575C0.075,-0.603 0.092,-0.627 0.115,-0.647C0.137,-0.668 0.165,-0.684 0.199,-0.697C0.234,-0.71 0.275,-0.716 0.323,-0.716C0.362,-0.716 0.398,-0.711 0.429,-0.702C0.461,-0.693 0.488,-0.681 0.51,-0.665C0.533,-0.648 0.55,-0.628 0.562,-0.604C0.574,-0.58 0.58,-0.553 0.58,-0.523C0.58,-0.485 0.571,-0.454 0.552,-0.429C0.533,-0.405 0.506,-0.387 0.47,-0.374L0.47,-0.37C0.509,-0.36 0.542,-0.341 0.567,-0.312C0.592,-0.283 0.605,-0.246 0.605,-0.199C0.605,-0.167 0.598,-0.138 0.585,-0.111C0.572,-0.085 0.553,-0.063 0.528,-0.044C0.503,-0.026 0.472,-0.012 0.436,-0.001C0.399,0.009 0.357,0.014 0.31,0.014Z" />
                </g>
            </Three>
            <Two>
                <g transform="matrix(272.805,0,0,267.242,13.6406,193.21)">
                    <path d="M0.012,-0.008L0.232,-0.265C0.252,-0.288 0.269,-0.308 0.285,-0.327C0.302,-0.346 0.315,-0.365 0.327,-0.382C0.338,-0.399 0.348,-0.415 0.354,-0.43C0.361,-0.445 0.364,-0.46 0.364,-0.475C0.364,-0.496 0.358,-0.513 0.345,-0.527C0.333,-0.541 0.314,-0.548 0.289,-0.548C0.266,-0.548 0.247,-0.541 0.232,-0.528C0.217,-0.514 0.209,-0.492 0.209,-0.461L0.209,-0.451L0.018,-0.451C0.018,-0.49 0.023,-0.526 0.035,-0.558C0.046,-0.591 0.064,-0.619 0.087,-0.642C0.11,-0.665 0.139,-0.683 0.174,-0.696C0.209,-0.709 0.25,-0.716 0.297,-0.716C0.338,-0.716 0.375,-0.711 0.408,-0.701C0.441,-0.691 0.469,-0.677 0.493,-0.658C0.517,-0.639 0.535,-0.617 0.547,-0.59C0.56,-0.563 0.566,-0.533 0.566,-0.5C0.566,-0.47 0.562,-0.443 0.552,-0.417C0.542,-0.392 0.529,-0.368 0.514,-0.345C0.498,-0.323 0.48,-0.3 0.46,-0.278C0.439,-0.255 0.419,-0.232 0.397,-0.208L0.358,-0.164L0.595,-0.164L0.595,0L0.012,0L0.012,-0.008Z" />
                </g>
            </Two>

            <One>            
                <g transform="matrix(272.805,0,0,267.242,42.6406,193.21)">
                    <path d="M0.144,-0.539L0.03,-0.539L0.06,-0.702L0.345,-0.702L0.345,0L0.144,0L0.144,-0.539Z" />
                </g>
            </One>
            <Burst src ="/images/white-burst.png" alt="starburst" />
            {/* <Background /> */}
            <OliviaHold src="/images/olivia-holding-1.png" alt="Olivia holding" />
            <OliviaThrowing src="/images/olivia-holding-2.png" alt="Olivia throwing" />
            <Pokeball src="/images/pokeball.png" alt="Pokeball" />
        </Wrapper>    
    )
};

const Wrapper = styled.div`
  position: absolute;
  overflow: hidden;
  background-color: black;
  height: 100%;
  width: 100%;
`;
const Number = css`
    height: 300px;
    left: 63%;
    opacity: 0;
    position: fixed;
    top: 59%;
    transform: translate(-50%, -50%);
    z-index: 1;
`

const Three = styled.svg`
    ${Number};
    animation: 1.3s ${fadeInOut} ease-out;
    animation-delay: .3s;
`;

const Two = styled.svg`
    ${Number};
    animation: 1.3s ${fadeInOut} ease-out;
    animation-delay: 1.4s;
`;

const One = styled.svg`
    ${Number};
    animation: 1.3s ${fadeInOut} ease-out;
    animation-delay: 2.9s;
`;

const Burst = styled.img`
    left: 53%;
    position: fixed;
    top: 51%;
    transform: translate(-50%, -50%);
    width: 300px;
    z-index: 0;
    animation: 1.3s ${starburst} ease-out;
    animation-delay: .3s;
    animation-iteration-count: 3;
    opacity: 0;
`;


const OliviaHold = styled.img`
    z-index: 1;
    animation: 2.9s ${oliviaHolding} ease-out;
    animation-delay: 4s;
    position: absolute;
    opacity: 0;
`;

const OliviaThrowing = styled.img`
    z-index: 2;
    animation: 10s ${oliviaThrowing} ease-out;
    animation-delay: 6.5s;
    position: absolute;
    opacity: 0;
`;

const Pokeball = styled.img`
    z-index: 2;
    animation: 8s ${pokeBall} ease-out;
    animation-delay: 7.2s;
    position: absolute;
    opacity: 0;
    @media screen and (min-width: 411px) {
      z-index: 2;
      animation: 8s ${pokeBall411} ease-out;
      animation-delay:7.2s;
      position: absolute;
      opacity: 0;
    }
    @media screen and (min-height: 812px) {
      z-index: 2;
      animation: 8s ${pokeBall812} ease-out;
      animation-delay:7.2s;
      position: absolute;
      opacity: 0;
    }

`;




export default withRouter(Countdown);



import { createGlobalStyle } from 'styled-components';
import styled, { keyframes } from 'styled-components';

export const GlobalStyle = createGlobalStyle `

@font-face {
    font-family: "raleway";
    font-style: normal;
    font-weight: 100;
    src: url(/fonts/raleway-thin-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: italic;
    font-weight: 100;
    src: url(/fonts/raleway-thinitalic-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: normal;
    font-weight: 200;
    src: url(/fonts/raleway-extralight-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: italic;
    font-weight: 200;
    src: url(/fonts/raleway-extralightitalic-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: normal;
    font-weight: 300;
    src: url(/fonts/raleway-light-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: italic;
    font-weight: 300;
    src: url(/fonts/raleway-lightitalic-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: normal;
    font-weight: 500;
    src: url(/fonts/raleway-regular-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: italic;
    font-weight: 500;
    src: url(/fonts/raleway-italic-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: normal;
    font-weight: 600;
    src: url(/fonts/raleway-bold-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: italic;
    font-weight: 600;
    src: url(/fonts/raleway-bolditalic-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: normal;
    font-weight: 700;
    src: url(/fonts/raleway-extrabold-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: italic;
    font-weight: 700;
    src: url(/fonts/raleway-extrabolditalic-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: normal;
    font-weight: 800;
    src: url(/fonts/raleway-black-webfont.woff2) format("woff2");
}

@font-face {
    font-family: "raleway";
    font-style: italic;
    font-weight: 800;
    src: url(/fonts/raleway-blackitalic-webfont.woff2) format("woff2");
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "raleway";
    font-style: normal;
}

body {
  margin: 0;
}

:root {
    --light-purple: gold;
}
`

export const Strong = styled.span`
    font-size: inherit;
    font-weight: bold;
`;


/**************************************/
/**************************************/
/************ ANIMATIONS **************/
/**************************************/
/**************************************/

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;


export const starburst = keyframes`
  0% {
    opacity: 0;
    width: 300px;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 1000px;
  }
`;


export const oliviaHolding = keyframes`
  0% {
    left: 10%;
    top: 5%;
    width: 330px;
    opacity: 0;
  }
  20% {
    left: 10%;
    top: 5%;
    width: 330px;
    opacity: 1;
  }
  100% {
    left: 10%;
    top: 5%;
    width: 330px;
    opacity: 1;
  }
  
`;

export const oliviaThrowing = keyframes`
  0% {
    opacity: 0;
    width: 370px;
    left: -2%;
    top: 0%;
  }
  10% {
    opacity: 1;
    width: 370px;
    left: -2%;
    top: 0%;
  }
  100% {
    opacity: 1;
    width: 370px;
    left: -2%;
    top: 0%;
  }
`;

  
export const pokeBall = keyframes`
  0% {
    left: 34%;
    opacity: 0;
    top: 15%;
    width:120px;
  }
  15% {
    left: 34%;
    opacity: 1;
    top: 15%;
    width:120px;
  }
 50% {
    left:-224%;
    opacity: 1;
    top: -103%;
    width: 2700px;
  }

  100% {
    left:-224%;
    opacity: 1;
    top: -103%;
    width: 2700px;
  }
`;

export const pokeBall411 = keyframes`
  0% {
    left: 32%;
    opacity: 0;
    top: 12%;
    width:120px;
  }
  15% {
    left: 32%;
    opacity: 1;
    top: 12%;
    width:120px;
  }
 50% {
    left:-224%;
    opacity: 1;
    top: -90%;
    width: 2700px;
  }

  100% {
    left:-224%;
    opacity: 1;
    top: -90%;
    width: 2700px;
  }
`;

export const pokeBall812 = keyframes`
  0% {
    left: 30.5%;
    opacity: 0;
    top: 10%;
    width: 120px;
  }
  15% {
    left: 30.5%;
    opacity: 1;
    top: 10%;
    width: 120px;
  }
 50% {
    left:-224%;
    opacity: 1;
    top: -70%;
    width: 2700px;
  }

  100% {
    left:-224%;
    opacity: 1;
    top: -70%;
    width: 2700px;
  }
`;

import React from 'react'
import styled from 'styled-components';
import { Link } from 'react-router-dom'

export default function Home() {
    return (
        <Wrapper>
            <Background>
                <img src="/images/white-burst.png" alt="white burst" />
            </Background>
            <Title>
                <img src="/images/5th.png" alt="birthday title" />
            </Title>
            <Misty>
                <img src="/images/olivia-holding-out.png" alt="Olivia holding Pokeball" />
            </Misty>
            <StyledLink to="/invitation"><span>Next</span><img src="/images/arrow.png" alt="arrow" /></StyledLink>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    height: 100vh;
    padding: 20px;
    position: relative;

`;

const Background = styled.div `
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    img {
        left: -72px;
        position: relative;
        top: 62px;
        @media screen and (min-width: 360px) {
            top: 122px;
            left: -55px;
        }
        @media screen and (min-height: 730px) {
            left: -73px;
            top: 135px;
            width: 140%;
        }
    }
`;

const Title = styled.div`
    position: relative;
    text-align: center;
    img {
        width: 82%;
    }
    @media screen and (min-width: 700px) {
        display: inline-block;
        float: left;
        width: 60%;
        padding: 30px;
        img {
            width: 100%;
        }
    }
    @media screen and (min-height: 812px) {
        img {
            width: 90%
        }
    }
`;

const Misty = styled.div`
    margin-top: -30px;
    position: relative;
    text-align: center;
    img {
        width: 65%;
    }
    @media screen and (min-width: 700px) {
        display: inline-block;
        float: right;
        width: 40%;
        padding: 90px 20px 20px 20px;
        img {
            width: 100%;
        }
    }
    @media screen and (min-height: 812px) {
        img {
            width: 75%
        }
    }
`;

const StyledLink = styled(Link)`
    bottom: 10px;
    left: 20px;
    position: fixed;
    text-decoration: none;
    &:hover {
        opacity: .7;
        cursor: pointer;
    }
    span {
        padding-right: 8px;
        color: red;
        font-size: 25px;
        font-weight: 600;      
        position: relative;
        top: -9px;
        font-style: italic;
    }
    img {
        width: 80px;
    }
    @media screen and (min-width: 700px) {
       left: 30px;
        img {
            width: 120px;
        }
    }
`;

import React, { Component } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import CloseIcon from '../components/CloseIcon';

// create a component
class Menu  extends Component {
    render() {
        const { toggleMenuState, closeMenu } = this.props;
        return (
            <MenuWrapper open={toggleMenuState}>
                <MenuSidebar>
                    <CloseMenu onClick={closeMenu}>
                        <CloseIcon />
                    </CloseMenu>
     
                    <MenuList>
                        <Label>Menu</Label>
                         <ListItem>
                            <StyledLink onClick={closeMenu} to="/countdown">                             
                                <Text>Countdown</Text>
                            </StyledLink>
                        </ListItem> 
                        <ListItem>
                            <StyledLink onClick={closeMenu} to="/home">                             
                                <Text>Home</Text>
                            </StyledLink>
                        </ListItem>   
                        <ListItem>
                            <StyledLink onClick={closeMenu} to="/invitation">                             
                                <Text>Invitation</Text>
                            </StyledLink>
                        </ListItem>
     
                        <OtherLabel>Older birthday sites</OtherLabel> 
                        <NumberItem>
                            <NumberedLink href="https://1.oliviarector.com" target="new">                             
                                <Text>1</Text>
                            </NumberedLink>
                        </NumberItem>
                        <NumberItem>
                            <NumberedLink href="https://2.oliviarector.com" target="new">                             
                                <Text>2</Text>
                            </NumberedLink>
                        </NumberItem>
                        <NumberItem>
                            <NumberedLink href="https://4.oliviarector.com" target="new">                             
                                <Text>4</Text>
                            </NumberedLink>
                        </NumberItem>

                    </MenuList>

                </MenuSidebar>
            </MenuWrapper>
        );
    }
}

// define your styles
const MenuWrapper = styled.div`
    -webkit-overflow-scrolling: touch;
    -webkit-transition: 0.4s;
    background-color: black;
    background-image: url('/images/pokemon-menu.jpg');

    box-sizing: border-box;
    height: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding: 0px;
    position: fixed;
    right: -100vw;
    top: 0;
    transition: 0.4s;
    width: 100%;
    z-index: 100;

    ${({ open }) =>
        open &&
        `
    right: 0;
    -webkit-transition: .4s;
            transition: .4s;
  `};
`;

const CloseMenu = styled.button`
    background: black;
    border-radius: 40px;
    border: 0;
    height: 35px;
    line-height: 40px;
    outline: none;
    position: absolute;
    right: 15px;
    text-align: center;
    top: 20px;
    width: 35px;
`;

const MenuSidebar = styled.div`
    margin: 15px 15px 10px 15px;
    text-align: left;
`;

const MenuList = styled.ul`
    box-sizing: border-box;
    padding: 0;
    margin: 0;
`;

const Label = styled.h1`
    color: white;
    font-size: 48px;
    font-weight: 300;
    margin: 20px 0 0 10px;
`;
const OtherLabel = styled.h1`
    color: white;
    font-size: 26px;
    font-style: italic;
    font-weight: 400;
    margin: 50px 0 0 10px;
`;

const ListItem = styled.li`
    align-items: center;
    background-color: rgba(0, 0, 0, .5);
    border-radius: 35px;
    box-sizing: border-box;
    display: flex;
    height: 45px;
    justify-content: center;
    margin: 18px 25px 18px 10px;
    position: relative;
    text-align: center;
    width: 60%;

    &.active {
        background-color: white;
        border-color: white;
        h4 {
            color: var(--light-purple);
        }
    }

`;

const NumberItem = styled.li`
    align-items: center;
    background-color: white;
    border-radius: 35px;
    box-sizing: border-box;
    display: inline-block;
    height: 40px;
    justify-content: center;
    margin: 15px 15px 0 10px;
    position: relative;
    text-align: center;
    width: 40px;

    &.active {
        background-color: white;
        border-color: white;
        h4 {
            color: var(--light-purple);
        }
    }


`;

const NumberedLink = styled.a`
    align-items: center;
    border-radius: 40px;
    border: 0;
    box-sizing: border-box;
    display: flex;
    height: 35px;
    justify-content: center;
    margin: 0;
    text-decoration: none;
    width: 100%;
    &.active {
        background: white;
        border: 0;
        h4 {
            color: black;
        }
    }
    h4 {
        color: black;
    }
`;

const StyledLink = styled(NavLink)`
    align-items: center;
    border-radius: 40px;
    border: 0;
    box-sizing: border-box;
    display: flex;
    height: 45px;
    justify-content: center;
    margin: 0;
    text-decoration: none;
    width: 100%;
    &.active {
        background: white;
        border: 0;
        h4 {
            color: black;
        }
    }
`;
const Text = styled.h4`
    color: white;
    font-size: 17px;
    letter-spacing: 1px;
`;


//make this component available to the app
export default Menu;
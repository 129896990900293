import React, { Component } from 'react';
import styled from 'styled-components';
// import local
import { GlobalStyle } from './DefaultStyles';
import Routing from "./pages/Routes";
import MenuIcon from './components/MenuIcon';
import Menu from './pages/Menu';




export default class App extends Component {
  constructor(props) {
      super(props);

      this.state = {
          toggle: false,
          toggleMenu: false,
          toggleButton: false,
      };
  }
  toggleButton = () => {
      this.setState({
          toggle: !this.state.toggle,
      });
  };
  toggleMenu = () => {
      this.setState({
          toggleMenu: !this.state.toggleMenu,
          toggleOverlay: !this.state.toggleOverlay,
          toggleButton: !this.state.toggleButton,
      });
  };
  closeMenuFirst = () => {
      this.setState({
          toggleMenu: false,
          toggleOverlay: false,
          toggleButton: false,
      });
  };
  render() {
    return (
      <Wrapper>
        <GlobalStyle />
        
        <MenuIconBg>
            <MenuIconBtn
                toggleMenuProps={this.toggleMenu}
                toggleButtonProps={this.state.toggleButton}
            />
            <MenuTitle>Menu</MenuTitle>
        </MenuIconBg>
        <Menu
            toggleMenuState={this.state.toggleMenu}
            toggleMenuProps={this.toggleMenu}
            closeMenu={this.closeMenuFirst}
        />
        <Routing />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  background-color: #000;
  /* height: 100vh;
  width: 100vw; */
`;

const MenuTitle = styled.span`
    color: white;
    font-size: 10px;
    font-weight: 600;
    position: absolute;
    right: 4px;
    text-transform: uppercase;
    top: -24px;
`;

const MenuIconBtn = styled(MenuIcon)`
`;

const MenuIconBg = styled.button`
    position: fixed;
    right: 15px;
    top: 22px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border: none;
    border-radius: 50%;
    outline: none;
    background: black;
    cursor: pointer;
    z-index: 9;
`;



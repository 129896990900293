import React from 'react'
import { Route, Switch } from 'react-router-dom';
import Home from "./Home";
import Countdown from "./Countdown";
import Invitation from "./Invitation";
import Thanks from "./Thanks";

export default function Routes() {
    return (
        <Switch>
            <Route exact path="/" component={ Countdown } /> 
            <Route exact path="/countdown" component={ Countdown } />
            <Route exact path="/home" component={ Home } />
            <Route exact path="/invitation" component={ Invitation } />
            <Route exact path="/thanks" component={ Thanks } />
        </Switch>
    )
}

import React from 'react';
import styled from 'styled-components';
import { fadeIn } from "../DefaultStyles";




export default function Invitation(props) {
  return (
        <Wrapper>
             <Info>
                <ul>
                    <Date><strong>Date</strong> <span>August 31</span></Date>
                    <Date><strong>Time</strong> <span>5:30-8:30pm</span></Date>
                    <Place><strong>Place</strong> <span>19661 W. Washington St., Buckeye AZ 85326</span></Place>
                </ul>
            </Info>
            <Form action="https://formspree.io/epliske@gmail.com" method="POST">
            <Input type="text" name="name" placeholder="Your name" required />
            <InputG type="text" name="_gotcha" />
            <Input
              type="number"
              name="number"
              placeholder="Total # attending"
              required
            />
            <Input
              type="email"
              name="_replyto"
              placeholder="Your email address"
              required
            />
            <Input
              type="hidden"
              name="_subject"
              value="RSVP for Olivia's 5th birthday!"
            />
            <Input
              type="hidden"
              name="_next"
              value="https://www.oliviarector.com/thanks"
            />
            <Button
              type="submit"
              value="Submit"
            >
                Send RSVP
            </Button>
          </Form>
     
             <Nash src="/images/nash-pika-pika.png" alt="Nash Pikachu" />
     
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background-color: rgb(208, 20, 34); 
    color: white;
    height: 100vh;
    width: 100%;
    animation: 1.3s ${fadeIn} ease-out;
`;

const Info = styled.div`
    color: white;
    margin: 0px auto 15px auto;
    padding: 25px 30px 0 20px;
    ul {
        list-style-type: none;
    }
    
    strong {
        background-color: gold;
        border-radius: 30px;
        color: black;
        padding: 2px 10px;
    }
    @media screen and (min-width: 375px) {
        margin-bottom: 25px;
    }
    @media screen and (min-height: 800px) {
        margin: 20px auto 35px auto;
    }
`;

const Date = styled.li  `
    margin-bottom: 15px;
    display: inline-block;

    span {
        display: inline-block;
        margin-left: 5px;
        vertical-align: top;
        width: 103px;
    }  
`;

const Place = styled.li  `
    margin-bottom: 15px;
    display: block;
    span {
        display: inline-block;
        margin-left: 5px;
        vertical-align: top;
        width: 170px;
        @media screen and (min-width: 360px) {
            width: 190px;
        }
    }  
`;

const Form = styled.form`
    display: block;
    width: 90%;
    margin: 0px auto 40px auto;
    padding-top: 20px;
    position: relative;
    @media screen and (min-width: 400px) {
        padding-top: 30px;
    }
`;

const Input = styled.input`
    border-radius: 10px;
    display: block;
    font-size: 17px;
    font-weight: 300;
    margin-bottom: 10px;
    padding: 10px 10px 6px 10px;
    width: 100%;
    @media screen and (min-width: 360px) {
        padding: 15px;
    }
    @media screen and (min-width: 400px) {
        padding: 20px;
    }
    @media screen and (min-height: 720px) {
        padding: 25px 25px 21px 25px;
    }
`;

const InputG = styled.input`
  display: none;
`;


const Button = styled.button`
  background-color: black;
  border-radius: 10px;
  border: 2px solid gold;
  color: white;
  display: block;
  font-size: 22px;
  font-weight: 700;
  margin: 20px auto;
  padding: 10px;
  width: 80%;
  @media screen and (min-width: 375px) {
        padding: 15px;
    }
`;


const Nash = styled.img`
    bottom: 0;
    position: fixed;
    width: 60%;
    margin-left: 17%;
`;
